import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import AddCustomerBusinessRisk from './Components/CustomerBusinessRisk/AddCustomerBusinessRisk';
import AddGeographicJurisdiction from './Components/GeographicJurisdiction/AddGeographicJurisdiction';
import AddTransProfProductChannel from './Components/TransProfProductChannel/AddTransProfProductChannel';
import AddTransactionProfiling from './Components/TransactionProfiling/AddTransactionProfiling';

function AddCustomerRiskProfile() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allStepsComplete, setAllStepsComplete] = useState(false);
    const [customerRiskProfileId, setCustomerRiskProfileId] = useState();
    const [customerRiskProfileData, setCustomerRiskProfileData] = useState();
    const [responseId, setResponseId] = useState();
    const [crpModelIdData, setCrpModelIdData] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const stateRpCrpId = location.state?.rpCrpId;

    const getRiskProfileDataIsDraftById = async () => {
        const response = await handleGetRequest(`/riskprofile/v1/crp/getcrp/${stateRpCrpId}`);
        setCustomerRiskProfileData(response?.payLoad);
        setActiveIndex(parseInt(response?.payLoad?.currentStep));
    };

    useEffect(() => {
        if (stateRpCrpId) {
            getRiskProfileDataIsDraftById();
        }
    }, []); //eslint-disable-line

    const getRiskProfileDataById = async () => {
        const response = await handleGetRequest(`/riskprofile/v1/crp/getcrp/${customerRiskProfileId}`);
        setCustomerRiskProfileData(response?.payLoad);
    };

    useEffect(() => {
        if (customerRiskProfileId) {
            getRiskProfileDataById();
        }
    }, [activeIndex]); //eslint-disable-line

    const formik = useFormik({
        initialValues: {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['crpId'] = responseId;

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: [data],
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/submitcrp', true, false, 'customerriskprofile'));
            if (res?.responseCode === '170000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('');
        }
    });

    const items = [
        {
            label: 'Customer / Business Risk',
            command: (e) => handleStepClick(e.index),
            disabled: customerRiskProfileData?.currentStep >= 1 ? false : true
        },
        {
            label: 'Geographic / Jurisdiction',
            command: (e) => handleStepClick(e.index),
            disabled: customerRiskProfileData?.currentStep >= 2 ? false : true
        },
        {
            label: 'Transaction Profiling - Products / Channels',
            command: (e) => handleStepClick(e.index),
            disabled: customerRiskProfileData?.currentStep >= 3 ? false : true
        },
        {
            label: 'Transaction Profiling',
            command: (e) => handleStepClick(e.index),
            disabled: customerRiskProfileData?.currentStep >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };
    const handlecrpId = (id) => {
        setResponseId(id);
    };

    const getcustomerRiskProfileId = (data) => {
        setCustomerRiskProfileId(data);
    };

    const handledAllStepsComplete = (data) => {
        setAllStepsComplete(data);
    };

    const handledCrpModelId = (data) => {
        setCrpModelIdData(data);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />

                {activeIndex === 0 && (
                    <AddCustomerBusinessRisk
                        activeIndex={activeIndex}
                        handlecrpId={handlecrpId}
                        handleStepClick={handleStepClick}
                        getcustomerRiskProfileId={getcustomerRiskProfileId}
                        customerRiskProfileData={customerRiskProfileData}
                        handledCrpModelId={handledCrpModelId}
                    />
                )}
                {activeIndex === 1 && (
                    <AddGeographicJurisdiction
                        activeIndex={activeIndex}
                        handleStepClick={handleStepClick}
                        customerRiskProfileId={customerRiskProfileId}
                        customerRiskProfileData={customerRiskProfileData}
                        responseId={responseId}
                        crpModelIdData={crpModelIdData}
                        getcustomerRiskProfileId={getcustomerRiskProfileId}
                    />
                )}
                {activeIndex === 2 && (
                    <AddTransProfProductChannel
                        activeIndex={activeIndex}
                        handleStepClick={handleStepClick}
                        customerRiskProfileId={customerRiskProfileId}
                        customerRiskProfileData={customerRiskProfileData}
                        responseId={responseId}
                        crpModelIdData={crpModelIdData}
                        getcustomerRiskProfileId={getcustomerRiskProfileId}
                    />
                )}
                {activeIndex === 3 && (
                    <AddTransactionProfiling
                        activeIndex={activeIndex}
                        handleStepClick={handleStepClick}
                        customerRiskProfileId={customerRiskProfileId}
                        customerRiskProfileData={customerRiskProfileData}
                        handledAllStepsComplete={handledAllStepsComplete}
                        getcustomerRiskProfileId={getcustomerRiskProfileId}
                        responseId={responseId}
                        crpModelIdData={crpModelIdData}
                        handlecrpId={handlecrpId}
                    />
                )}
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex === 4 && allStepsComplete && (
                        <div className="Down__Btn">
                            <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Submit" className="Btn__Dark" />
                            <Button disabled={loading} onClick={() => navigate('/customerriskprofile')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default AddCustomerRiskProfile;
