import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import EditCustomerBusinessRisk from './Components/CustomerBusinessRisk/EditCustomerBusinessRisk';
import EditGeographicJurisdiction from './Components/GeographicJurisdiction/EditGeographicJurisdiction';
import EditTransProfProductChannel from './Components/TransProfProductChannel/EditTransProfProductChannel';
import EditTransactionProfiling from './Components/TransactionProfiling/EditTransactionProfiling';

function EditCustomerRiskProfile() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allStepsComplete, setAllStepsComplete] = useState(false);
    const [riskProfileData, setRiskProfileData] = useState();
    const [stepData1, setStepData1] = useState();
    const [stepData2, setStepData2] = useState();
    const [stepData3, setStepData3] = useState();
    const [stepData4, setStepData4] = useState();
    const [crpModelIdData, setCrpModelIdData] = useState();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const riskProfileId = query.get('riskProfileId');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getRiskProfileDataById = async () => {
        const response = await handleGetRequest(`/riskprofile/v1/crp/getcrp/${riskProfileId}`);
        setRiskProfileData(response?.payLoad);
    };

    useEffect(() => {
        getRiskProfileDataById();
    }, []); //eslint-disable-line

    const formik = useFormik({
        initialValues: {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newArrayData = [stepData1, stepData2, stepData3, stepData4];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: newArrayData,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/updatecrp', true, false, 'customerriskprofile'));
            if (res?.responseCode === '170000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('');
        }
    });


   

    const items = [
        {
            label: 'Customer / Business Risk',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 0 ? false : true,
            className: parseInt(riskProfileData?.currentStep) >= 0 ? '' : ''
        },
        {
            label: 'Geographic / Jurisdiction',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 1 ? false : true
        },
        {
            label: 'Transaction Profiling - Products / Channels',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 2 ? false : true
        },
        {
            label: 'Transaction Profiling',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 3 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    const handledAllStepsComplete = (data) => {
        setAllStepsComplete(data);
    };

    const handledCrpModelId = (data) => {
        setCrpModelIdData(data);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />

                {activeIndex === 0 && <EditCustomerBusinessRisk activeIndex={activeIndex} handleStepClick={handleStepClick} riskProfileData={riskProfileData} setStepData1={setStepData1} handledCrpModelId={handledCrpModelId} />}
                {activeIndex === 1 && <EditGeographicJurisdiction activeIndex={activeIndex} handleStepClick={handleStepClick} riskProfileData={riskProfileData} setStepData2={setStepData2} crpModelIdData={crpModelIdData} />}
                {activeIndex === 2 && <EditTransProfProductChannel activeIndex={activeIndex} handleStepClick={handleStepClick} riskProfileData={riskProfileData} setStepData3={setStepData3} crpModelIdData={crpModelIdData} />}
                {activeIndex === 3 && (
                    <EditTransactionProfiling
                        activeIndex={activeIndex}
                        handleStepClick={handleStepClick}
                        riskProfileData={riskProfileData}
                        setStepData4={setStepData4}
                        handledAllStepsComplete={handledAllStepsComplete}
                        crpModelIdData={crpModelIdData}
                    />
                )}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex === 4 && allStepsComplete && (
                        <div className="Down__Btn">
                            <Button disabled={loading} icon={loadingIcon || ''} label="Submit" className="Btn__Dark" />
                            <Button disabled={loading} onClick={() => navigate('/customerriskprofile')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default EditCustomerRiskProfile;
