import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';

function EditCustomerBusinessRisk({ activeIndex, handleStepClick, riskProfileData, setStepData1, handledCrpModelId }) {

    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [customerType, setCustomerType] = useState([]);
    const [occupationLov, setOccupationLov] = useState([]);
    const [sourceOfIncomeLov, setSourceOfIncomeLov] = useState([]);
    const [accountPurposeLov, setAccountPurposeLov] = useState([]);
    const [rpCrpModelId, setRpCrpModelId] = useState([]);

    // Customer Type
    const [selectedCustomerTypeHighRisk, setSelectedCustomerTypeHighRisk] = useState([]);
    const [selectedCustomerTypeMediumRisk, setSelectedCustomerTypeMediumRisk] = useState([]);
    const [selectedCustomerTypeLowRisk, setSelectedCustomerTypeLowRisk] = useState([]);

    const combinedCustomerTypeMediumLowRisk = [...selectedCustomerTypeMediumRisk, ...selectedCustomerTypeLowRisk];
    const combinedCustomerTypeHighLowRisk = [...selectedCustomerTypeHighRisk, ...selectedCustomerTypeLowRisk];
    const combinedCustomerTypeHighMediumRisk = [...selectedCustomerTypeHighRisk, ...selectedCustomerTypeMediumRisk];

    // Occupation
    const [selectedOccupationHighRisk, setSelectedOccupationHighRisk] = useState([]);
    const [selectedOccupationMediumRisk, setSelectedOccupationMediumRisk] = useState([]);
    const [selectedOccupationLowRisk, setSelectedOccupationLowRisk] = useState([]);

    const combinedOccupationMediumLowRisk = [...selectedOccupationMediumRisk, ...selectedOccupationLowRisk];
    const combinedOccupationHighLowRisk = [...selectedOccupationHighRisk, ...selectedOccupationLowRisk];
    const combinedOccupationHighMediumRisk = [...selectedOccupationHighRisk, ...selectedOccupationMediumRisk];

    // Purpose
    const [selectedPurposeHighRisk, setSelectedPurposeHighRisk] = useState([]);
    const [selectedPurposeMediumRisk, setSelectedPurposeMediumRisk] = useState([]);
    const [selectedPurposeLowRisk, setSelectedPurposeLowRisk] = useState([]);

    const combinedPurposeMediumLowRisk = [...selectedPurposeMediumRisk, ...selectedPurposeLowRisk];
    const combinedPurposeHighLowRisk = [...selectedPurposeHighRisk, ...selectedPurposeLowRisk];
    const combinedPurposeHighMediumRisk = [...selectedPurposeHighRisk, ...selectedPurposeMediumRisk];

    // Source
    const [selectedSourceHighRisk, setSelectedSourceHighRisk] = useState([]);
    const [selectedSourceMediumRisk, setSelectedSourceMediumRisk] = useState([]);
    const [selectedSourceLowRisk, setSelectedSourceLowRisk] = useState([]);

    const combinedSourceMediumLowRisk = [...selectedSourceMediumRisk, ...selectedSourceLowRisk];
    const combinedSourceHighLowRisk = [...selectedSourceHighRisk, ...selectedSourceLowRisk];
    const combinedSourceHighMediumRisk = [...selectedSourceHighRisk, ...selectedSourceMediumRisk];

    const [high, setHigh] = useState(null);
    const [med, setMed] = useState(null);
    const [lov, setLov] = useState([]);

    const getRpCrpModelId = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/TBL_RP_CRP_MODEL');
        setRpCrpModelId(response?.payLoad);
    };
    const getCustomerType = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_CUSTOMER_TYPES');
        setCustomerType(response?.payLoad);
    };

    const getHraLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gethraaccountdata');
        setOccupationLov(resp?.payLoad?.lkpOccupation);
        setSourceOfIncomeLov(resp?.payLoad?.lkpSourceOfIncome);
        setAccountPurposeLov(resp?.payLoad?.lkpAccountPurpose);
    };

    useEffect(() => {
        getRpCrpModelId();
        getCustomerType();
        getHraLov();
    }, []);

    useEffect(() => {
        if (riskProfileData) {
            formik.setFieldValue('crpCode', riskProfileData?.crpCode);
            formik.setFieldValue('crpName', riskProfileData?.crpName);
            formik.setFieldValue('crpDescription', riskProfileData?.description);
            formik.setFieldValue('rpCrpModelId', riskProfileData?.tblRpCrpModel?.rpCrpModelId?.toString());

            const CTDropdownData = riskProfileData?.tblRpCrpDetails;

            if (CTDropdownData) {
                const CTHighId = [];
                const CTMediumId = [];
                const CTLowId = [];

                const pepHighId = [];
                const pepMediumId = [];
                const pepLowId = [];

                const occupationHighId = [];
                const occupationMediumId = [];
                const occupationLowId = [];

                const customerLinkedHighId = [];
                const customerLinkedMediumId = [];
                const customerLinkedLowId = [];

                const purposeOfACHighId = [];
                const purposeOfACMediumId = [];
                const purposeOfACLowId = [];

                const sourceOfFundsHighId = [];
                const sourceOfFundsMediumId = [];
                const sourceOfFundsLowId = [];

                CTDropdownData.forEach((value) => {
                    if (value?.isActive === 'Y') {
                        const referenceIdToString = value.refId?.toString() || value?.refId;
                        const referenceId = value?.refId || value.refId?.toString();
                        // Get Customer Type Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CT') {
                            if (value?.rpRating === 'H') {
                                CTHighId.push(referenceIdToString);

                                formik.setFieldValue('CTHighRisk', CTHighId);
                                setSelectedCustomerTypeHighRisk(CTHighId);
                                formik.setFieldValue('CToverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CTMediumId.push(referenceIdToString);
                                formik.setFieldValue('CTMediumRisk', CTMediumId);
                                setSelectedCustomerTypeMediumRisk(CTMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CTLowId.push(referenceIdToString);
                                formik.setFieldValue('CTLowRisk', CTLowId);
                                // setSelectedCustomerTypeLowRisk(CTLowId);
                            }
                        }
                        // GET PEP Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PEP') {
                            if (value?.rpRating === 'H') {
                                pepHighId.push(referenceIdToString);

                                formik.setFieldValue('pepHighRisk', pepHighId[0] === '0' ? 'N' : 'Y');
                                formik.setFieldValue('pepOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                pepMediumId.push(referenceIdToString);
                                formik.setFieldValue('pepMediumRisk', pepMediumId[0] === '0' ? 'N' : 'Y');
                            }
                            if (value?.rpRating === 'L') {
                                pepLowId.push(referenceIdToString);
                                formik.setFieldValue('pepLowRisk', pepLowId[0] === '0' ? 'N' : 'Y');
                            }
                        }

                        // GET occupation Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'OC') {
                            if (value?.rpRating === 'H') {
                                occupationHighId.push(referenceId);

                                formik.setFieldValue('occupationHighRisk', occupationHighId);
                                setSelectedOccupationHighRisk(occupationHighId);
                                formik.setFieldValue('occupationOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                occupationMediumId.push(referenceId);
                                formik.setFieldValue('occupationMediumRisk', occupationMediumId);
                                setSelectedOccupationMediumRisk(occupationMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                occupationLowId.push(referenceId);
                                formik.setFieldValue('occupationLowRisk', occupationLowId);
                                // setSelectedOccupationLowRisk(occupationLowId);
                            }
                        }

                        // GET Customer Linked Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CCT') {
                            if (value?.rpRating === 'H') {
                                customerLinkedHighId.push(referenceIdToString);
                                formik.setFieldValue('customerLinkedHighRisk', customerLinkedHighId[0] === '0' ? 'N' : 'Y');
                                formik.setFieldValue('customerLinkedOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                                // setSelectedCustomerLinkHighRisk(customerLinkedHighId)
                            }
                            if (value?.rpRating === 'M') {
                                customerLinkedMediumId.push(referenceIdToString);
                                formik.setFieldValue('customerLinkedMediumRisk', customerLinkedMediumId[0] === '0' ? 'N' : 'Y');
                                // setSelectedCustomerLinkMediumRisk(customerLinkedMediumId)
                            }
                            if (value?.rpRating === 'L') {
                                customerLinkedLowId.push(referenceIdToString);
                                formik.setFieldValue('customerLinkedLowRisk', customerLinkedLowId[0] === '0' ? 'N' : 'Y');
                                // setSelectedCustomerLinkLowRisk(customerLinkedLowId)
                            }
                        }

                        // GET Purpose of AC Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PA') {
                            if (value?.rpRating === 'H') {
                                purposeOfACHighId.push(referenceId);

                                formik.setFieldValue('purposeOfACHighRisk', purposeOfACHighId);
                                setSelectedPurposeHighRisk(purposeOfACHighId);
                                formik.setFieldValue('purposeOfACOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                purposeOfACMediumId.push(referenceId);
                                formik.setFieldValue('purposeOfACMediumRisk', purposeOfACMediumId);
                                setSelectedPurposeMediumRisk(purposeOfACMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                purposeOfACLowId.push(referenceId);
                                formik.setFieldValue('purposeOfACLowRisk', purposeOfACLowId);
                                // setSelectedPurposeLowRisk(purposeOfACLowId);
                            }
                        }

                        // GET Source of Funds Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'SF') {
                            if (value?.rpRating === 'H') {
                                sourceOfFundsHighId.push(referenceId);

                                formik.setFieldValue('sourceOfFundsHighRisk', sourceOfFundsHighId);
                                setSelectedSourceHighRisk(sourceOfFundsHighId);
                                formik.setFieldValue('sourceOfFundsOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                sourceOfFundsMediumId.push(referenceId);
                                formik.setFieldValue('sourceOfFundsMediumRisk', sourceOfFundsMediumId);
                                setSelectedSourceMediumRisk(sourceOfFundsMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                sourceOfFundsLowId.push(referenceId);
                                formik.setFieldValue('sourceOfFundsLowRisk', sourceOfFundsLowId);
                                // setSelectedSourceLowRisk(sourceOfFundsLowId);
                            }
                        }
                    }
                });
            }
        }
    }, [riskProfileData]);

    const formik = useFormik({
        initialValues: {
            stepNo: 1,
            crpCode: '',
            crpName: '',
            rpCrpModelId: '',
            crpDescription: '',
            CTHighRisk: [],
            CTMediumRisk: [],
            CTLowRisk: [],
            CToverRideRule: 'false',
            pepHighRisk: [],
            pepMediumRisk: [],
            pepLowRisk: [],
            pepOverRideRule: 'false',
            customerLinkedHighRisk: [],
            customerLinkedMediumRisk: [],
            customerLinkedLowRisk: [],
            customerLinkedOverRideRule: 'false',
            occupationHighRisk: [],
            occupationMediumRisk: [],
            occupationLowRisk: [],
            occupationOverRideRule: 'false',
            purposeOfACHighRisk: [],
            purposeOfACMediumRisk: [],
            purposeOfACLowRisk: [],
            purposeOfACOverRideRule: 'false',
            sourceOfFundsHighRisk: [],
            sourceOfFundsMediumRisk: [],
            sourceOfFundsLowRisk: [],
            sourceOfFundsOverRideRule: 'false',
            reqMap: []
        },
        validate: (data) => {
            let errors = {};
            if (!data.crpCode) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpCode = 'This field is required.';
            }
            if (!/^[a-zA-Z0-9_-]+$/.test(data?.crpCode)) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpCode = 'Invalid Input';
            }
            if (!data?.rpCrpModelId?.length === 0) {
                errors.rpCrpModelId = 'This field is required.';
            }
            if (!data.crpName) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpName = 'This field is required.';
            } else if (!/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/.test(data.crpName)) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpName = 'Only alphabets and numbers are allowed';
            }
            if (!data.crpDescription) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpDescription = 'This field is required.';
            } else if (!/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/.test(data.crpDescription)) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpDescription = 'Only alphabets and numbers are allowed';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['crpId'] = riskProfileData?.rpCrpId;

            handledCrpModelId(data?.rpCrpModelId);

            data['reqMap'] = [
                {
                    CT: {
                        highRisk: data?.CTHighRisk,
                        medRisk: data?.CTMediumRisk,
                        lowRisk: data?.CTLowRisk,
                        overRideRule: data?.CToverRideRule
                    }
                },
                {
                    PEP: {
                        highRisk: data?.pepHighRisk === "N" ? ["0"] : ["1"],
                        medRisk: data?.pepMediumRisk === "N" ? ["0"] : ["1"],
                        lowRisk: data?.pepLowRisk === "N" ? ["0"] : ["1"],
                        overRideRule: data?.pepOverRideRule
                    }
                },
                {
                    OC: {
                        highRisk: data?.occupationHighRisk,
                        medRisk: data?.occupationMediumRisk,
                        lowRisk: data?.occupationLowRisk,
                        overRideRule: data?.occupationOverRideRule
                    }
                },
                {
                    PA: {
                        highRisk: data?.purposeOfACHighRisk,
                        medRisk: data?.purposeOfACMediumRisk,
                        lowRisk: data?.purposeOfACLowRisk,
                        overRideRule: data?.purposeOfACOverRideRule
                    }
                },
                {
                    SF: {
                        highRisk: data?.sourceOfFundsHighRisk,
                        medRisk: data?.sourceOfFundsMediumRisk,
                        lowRisk: data?.sourceOfFundsLowRisk,
                        overRideRule: data?.sourceOfFundsOverRideRule
                    }
                },
                {
                    CCT: {
                        highRisk: data?.customerLinkedHighRisk === "N" ? ["0"] : ["1"],
                        medRisk: data?.customerLinkedMediumRisk === "N" ? ["0"] : ["1"],
                        lowRisk: data?.customerLinkedLowRisk === "N" ? ["0"] : ["1"],
                        overRideRule: data?.customerLinkedOverRideRule
                    }
                }
            ];

            delete data['CTHighRisk'];
            delete data['CTMediumRisk'];
            delete data['CTLowRisk'];
            delete data['CToverRideRule'];
            delete data['pepHighRisk'];
            delete data['pepMediumRisk'];
            delete data['pepLowRisk'];
            delete data['pepOverRideRule'];
            delete data['occupationHighRisk'];
            delete data['occupationMediumRisk'];
            delete data['occupationLowRisk'];
            delete data['occupationOverRideRule'];
            delete data['purposeOfACHighRisk'];
            delete data['purposeOfACMediumRisk'];
            delete data['purposeOfACLowRisk'];
            delete data['purposeOfACOverRideRule'];
            delete data['sourceOfFundsHighRisk'];
            delete data['sourceOfFundsMediumRisk'];
            delete data['sourceOfFundsLowRisk'];
            delete data['sourceOfFundsOverRideRule'];
            delete data['customerLinkedHighRisk'];
            delete data['customerLinkedMediumRisk'];
            delete data['customerLinkedLowRisk'];
            delete data['customerLinkedOverRideRule'];

            handleStepClick(activeIndex + 1);
            setStepData1(data);

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const staticLov = [
        { name: 'Yes', value: 'Y' },
        { name: 'No', value: 'N' }
    ];

    const setHighValue = (highValue) => {
        setHigh(highValue);
    };

    const setMedValue = (medValue) => {
        setMed(medValue);
    };

    const setLovValues = (lov) => {
        setLov(lov);
    };

    useEffect(() => {
        if (lov && (high || med)) {
            // selected values high & medium
            const selectedHighValues = formik?.values?.[high] || [];
            const selectedMedValues = formik?.values?.[med] || [];

            // combine selected high & medium values
            const selectedCtHighMedValues = [...selectedHighValues, ...selectedMedValues];

            // all values from lov
            const allValuesArr = lov.map((values) =>
                values.lovId || values.occupationId || values.accountPurposeId || values.sourceOfIncomeId
            );

            // remaining values for Low by excluding selected High and Medium values
            const remainingValues = allValuesArr.filter((value) => !selectedCtHighMedValues.includes(value));

            // Set Low dropdown field based on High (replace 'High' with 'Low')
            const formikLowField = high ? high.replace('High', 'Low') : med.replace('Medium', 'Low');
            formik.setFieldValue(formikLowField, remainingValues);
        }
    }, [high, med, formik?.values?.[high], formik?.values?.[med], lov]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpCode" className={classNames({ 'p-error': isFormFieldValid('crpCode') }, 'Label__Text')}>
                                    CRP Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpCode"
                                    placeholder="Enter CRP Code"
                                    name="crpCode"
                                    maxLength={20}
                                    value={formik?.values?.crpCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpName" className={classNames({ 'p-error': isFormFieldValid('crpName') }, 'Label__Text')}>
                                    CRP Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpName"
                                    placeholder="Enter Name"
                                    name="crpName"
                                    maxLength={50}
                                    value={formik?.values?.crpName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rpCrpModelId" className={classNames({ 'p-error': isFormFieldValid('rpCrpModelId') }, 'Label__Text')}>
                                    CRP Model
                                </label>
                                <Dropdown
                                    filter
                                    id="rpCrpModelId"
                                    placeholder="Select CRP Model"
                                    options={rpCrpModelId}
                                    optionLabel="name"
                                    name="rpCrpModelId"
                                    optionValue="lovId"
                                    value={formik.values.rpCrpModelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rpCrpModelId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('rpCrpModelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpDescription" className={classNames({ 'p-error': isFormFieldValid('crpDescription') }, 'Label__Text')}>
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpDescription"
                                    placeholder="Enter Description"
                                    name="crpDescription"
                                    maxLength={200}
                                    value={formik?.values?.crpDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpDescription') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpDescription')}
                            </div>
                        </div>
                    </div>

                    {/* Customer Type */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER TYPE</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTHighRisk" className={classNames({ 'p-error': isFormFieldValid('CTHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="CTHighRisk"
                                    placeholder="Select High Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.CTHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCustomerTypeHighRisk(e.value);
                                        formik.setFieldValue('CTHighRisk', e.value);
                                        formik.setFieldValue('CToverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("CTHighRisk");
                                        setLovValues(customerType)
                                    }}
                                    optionDisabled={(option) => combinedCustomerTypeMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTMediumRisk" className={classNames({ 'p-error': isFormFieldValid('CTMediumRisk') }, 'Label__Text')}>
                                    Medium
                                </label>
                                <MultiSelect
                                    id="CTMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.CTMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCustomerTypeMediumRisk(e.value);
                                        formik.setFieldValue('CTMediumRisk', e.value);
                                        setMedValue("CTMediumRisk");
                                        setLovValues(customerType)
                                    }}
                                    optionDisabled={(option) => combinedCustomerTypeHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTLowRisk" className={classNames({ 'p-error': isFormFieldValid('CTLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="CTLowRisk"
                                    placeholder="Select Low Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.CTLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCustomerTypeLowRisk(e.value);
                                        formik.setFieldValue('CTLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedCustomerTypeHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="CToverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="CToverRideRule" disabled name="CToverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.CToverRideRule === 'false'} />
                                    <label htmlFor="CToverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="CToverRideRule" disabled name="CToverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.CToverRideRule === 'true'} />
                                    <label htmlFor="CToverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PEP */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PEP</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepHighRisk" className={classNames({ 'p-error': isFormFieldValid('pepHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <Dropdown
                                    id="pepHighRisk"
                                    placeholder="Select High Risk"
                                    options={staticLov}
                                    name="pepHighRisk"
                                    value={formik?.values?.pepHighRisk || ''}
                                    optionValue="value"
                                    optionLabel="name"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('pepOverRideRule', e.value === "Y" ? 'true' : "false");
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepHighRisk') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('pepHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepMediumRisk" className={classNames({ 'p-error': isFormFieldValid('pepMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <Dropdown
                                    id="pepMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={staticLov}
                                    name="pepMediumRisk"
                                    optionValue="value"
                                    optionLabel="name"
                                    value={formik?.values?.pepMediumRisk || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepMediumRisk') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('pepMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepLowRisk" className={classNames({ 'p-error': isFormFieldValid('pepLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <Dropdown
                                    id="pepLowRisk"
                                    placeholder="Select Low Risk"
                                    options={staticLov}
                                    name="pepLowRisk"
                                    optionValue="value"
                                    optionLabel="name"
                                    value={formik?.values?.pepLowRisk || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepLowRisk') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('pepLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="pepOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="pepOverRideRule" disabled name="pepOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.pepOverRideRule === 'false'} />
                                    <label htmlFor="pepOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="pepOverRideRule" disabled name="pepOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.pepOverRideRule === 'true'} />
                                    <label htmlFor="pepOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CUSTOMER IS LINKED TO NGO/NPO/CHARITIES/TRUSTS */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER IS LINKED TO NGO/NPO/CHARITIES/TRUSTS/CLUBS/SOCIETIES/ASSOCIATION/EXCHANGE CO</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedHighRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedHighRisk"
                                    placeholder="Select High Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedHighRisk"
                                    optionValue="value"
                                    value={formik?.values?.customerLinkedHighRisk || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('customerLinkedOverRideRule', e.value === "Y" ? 'true' : "false");
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedHighRisk') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('customerLinkedHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedMediumRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedMediumRisk"
                                    optionValue="value"
                                    value={formik?.values?.customerLinkedMediumRisk || ''}
                                    onChange={formik.handleChange}
                                    // onChange={(e) => {
                                    //     setSelectedCustomerLinkMediumRisk(e.value);
                                    //     formik.setFieldValue('customerLinkedMediumRisk', e.value);
                                    // }}
                                    // optionDisabled={(option) => combinedCustomerLinkHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedMediumRisk') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('customerLinkedMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedLowRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedLowRisk"
                                    placeholder="Select Low Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedLowRisk"
                                    optionValue="value"
                                    value={formik?.values?.customerLinkedLowRisk || ''}
                                    onChange={formik.handleChange}
                                    // onChange={(e) => {
                                    //     setSelectedCustomerLinkLowRisk(e.value);
                                    //     formik.setFieldValue('customerLinkedLowRisk', e.value);
                                    // }}
                                    // optionDisabled={(option) => combinedCustomerLinkHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedLowRisk') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('customerLinkedLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="customerLinkedOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="customerLinkedOverRideRule" disabled name="customerLinkedOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.customerLinkedOverRideRule === 'false'} />
                                    <label htmlFor="customerLinkedOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="customerLinkedOverRideRule" disabled name="customerLinkedOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.customerLinkedOverRideRule === 'true'} />
                                    <label htmlFor="customerLinkedOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* OCCUPATION */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>OCCUPATION</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationHighRisk" className={classNames({ 'p-error': isFormFieldValid('occupationHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="occupationHighRisk"
                                    placeholder="Select High Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    name="occupationHighRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedOccupationHighRisk(e.value);
                                        formik.setFieldValue('occupationHighRisk', e.value);
                                        formik.setFieldValue('occupationOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("occupationHighRisk");
                                        setLovValues(occupationLov)
                                    }}
                                    optionDisabled={(option) => combinedOccupationMediumLowRisk.includes(option.occupationId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationMediumRisk" className={classNames({ 'p-error': isFormFieldValid('occupationMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="occupationMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    name="occupationMediumRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedOccupationMediumRisk(e.value);
                                        formik.setFieldValue('occupationMediumRisk', e.value);
                                        setMedValue("occupationMediumRisk");
                                        setLovValues(occupationLov)
                                    }}
                                    optionDisabled={(option) => combinedOccupationHighLowRisk.includes(option.occupationId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationLowRisk" className={classNames({ 'p-error': isFormFieldValid('occupationLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="occupationLowRisk"
                                    placeholder="Select Low Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    name="occupationLowRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedOccupationLowRisk(e.value);
                                        formik.setFieldValue('occupationLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedOccupationHighMediumRisk.includes(option.occupationId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="occupationOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="occupationOverRideRule" disabled name="occupationOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.occupationOverRideRule === 'false'} />
                                    <label htmlFor="occupationOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="occupationOverRideRule" disabled name="occupationOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.occupationOverRideRule === 'true'} />
                                    <label htmlFor="occupationOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PURPOSE OF A/C */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PURPOSE OF A/C</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACHighRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACHighRisk"
                                    placeholder="Select High Risk"
                                    options={accountPurposeLov}
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACHighRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedPurposeHighRisk(e.value);
                                        formik.setFieldValue('purposeOfACHighRisk', e.value);
                                        formik.setFieldValue('purposeOfACOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("purposeOfACHighRisk");
                                        setLovValues(accountPurposeLov)
                                    }}
                                    optionDisabled={(option) => combinedPurposeMediumLowRisk.includes(option.accountPurposeId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACMediumRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={accountPurposeLov}
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACMediumRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedPurposeMediumRisk(e.value);
                                        formik.setFieldValue('purposeOfACMediumRisk', e.value);
                                        setMedValue("purposeOfACMediumRisk");
                                        setLovValues(accountPurposeLov)
                                    }}
                                    optionDisabled={(option) => combinedPurposeHighLowRisk.includes(option.accountPurposeId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACLowRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACLowRisk"
                                    placeholder="Select Low Risk"
                                    options={accountPurposeLov}
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACLowRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedPurposeLowRisk(e.value);
                                        formik.setFieldValue('purposeOfACLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedPurposeHighMediumRisk.includes(option.accountPurposeId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="purposeOfACOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="purposeOfACOverRideRule" disabled name="purposeOfACOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.purposeOfACOverRideRule === 'false'} />
                                    <label htmlFor="purposeOfACOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="purposeOfACOverRideRule" disabled name="purposeOfACOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.purposeOfACOverRideRule === 'true'} />
                                    <label htmlFor="purposeOfACOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* SOURCE OF FUNDS */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>SOURCE OF FUNDS</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsHighRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsHighRisk"
                                    placeholder="Select High Risk"
                                    options={sourceOfIncomeLov}
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsHighRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedSourceHighRisk(e.value);
                                        formik.setFieldValue('sourceOfFundsHighRisk', e.value);
                                        formik.setFieldValue('sourceOfFundsOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("sourceOfFundsHighRisk");
                                        setLovValues(sourceOfIncomeLov)
                                    }}
                                    optionDisabled={(option) => combinedSourceMediumLowRisk.includes(option.sourceOfIncomeId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsMediumRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={sourceOfIncomeLov}
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsMediumRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedSourceMediumRisk(e.value);
                                        formik.setFieldValue('sourceOfFundsMediumRisk', e.value);
                                        setMedValue("sourceOfFundsMediumRisk");
                                        setLovValues(sourceOfIncomeLov)
                                    }}
                                    optionDisabled={(option) => combinedSourceHighLowRisk.includes(option.sourceOfIncomeId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsLowRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsLowRisk"
                                    placeholder="Select Low Risk"
                                    options={sourceOfIncomeLov}
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsLowRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedSourceLowRisk(e.value);
                                        formik.setFieldValue('sourceOfFundsLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedSourceHighMediumRisk.includes(option.sourceOfIncomeId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="sourceOfFundsOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="sourceOfFundsOverRideRule" disabled name="sourceOfFundsOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.sourceOfFundsOverRideRule === 'false'} />
                                    <label htmlFor="sourceOfFundsOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="sourceOfFundsOverRideRule" disabled name="sourceOfFundsOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.sourceOfFundsOverRideRule === 'true'} />
                                    <label htmlFor="sourceOfFundsOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Next" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditCustomerBusinessRisk;
