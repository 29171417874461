import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';

function EditTransProfProductChannel({ activeIndex, handleStepClick, riskProfileData, setStepData3, crpModelIdData }) {

    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [productLov, setProductLov] = useState([]);
    const [channelOption, setChannelOption] = useState([]);

    // Products/Services
    const [selectedProductsHighRisk, setSelectedProductsHighRisk] = useState([]);
    const [selectedProductsMediumRisk, setSelectedProductsMediumRisk] = useState([]);
    const [selectedProductsLowRisk, setSelectedProductsLowRisk] = useState([]);

    const combinedProductsMediumLowRisk = [...selectedProductsMediumRisk, ...selectedProductsLowRisk];
    const combinedProductsHighLowRisk = [...selectedProductsHighRisk, ...selectedProductsLowRisk];
    const combinedProductsHighMediumRisk = [...selectedProductsHighRisk, ...selectedProductsMediumRisk];

    // Channel
    const [selectedChannelHighRisk, setSelectedChannelHighRisk] = useState([]);
    const [selectedChannelMediumRisk, setSelectedChannelMediumRisk] = useState([]);
    const [selectedChannelLowRisk, setSelectedChannelLowRisk] = useState([]);

    const combinedChannelMediumLowRisk = [...selectedChannelMediumRisk, ...selectedChannelLowRisk];
    const combinedChannelHighLowRisk = [...selectedChannelHighRisk, ...selectedChannelLowRisk];
    const combinedChannelHighMediumRisk = [...selectedChannelHighRisk, ...selectedChannelMediumRisk];

    const [high, setHigh] = useState(null);
    const [med, setMed] = useState(null);
    const [lov, setLov] = useState([]);

    const getProductsLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProductLov(resp?.payLoad);
    };

    const formik = useFormik({
        initialValues: {
            stepNo: 3,
            productServicesHighRisk: [],
            productServicesMediumRisk: [],
            productServicesLowRisk: [],
            productServicesOverRideRule: 'false',
            channelHighRisk: [],
            channelMediumRisk: [],
            channelLowRisk: [],
            channelOverRideRule: 'false',
            reqMap: []
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['crpId'] = riskProfileData?.rpCrpId;
            data['rpCrpModelId'] = crpModelIdData;

            data['reqMap'] = [
                {
                    PS: {
                        highRisk: data?.productServicesHighRisk,
                        medRisk: data?.productServicesMediumRisk,
                        lowRisk: data?.productServicesLowRisk,
                        overRideRule: data?.productServicesOverRideRule
                    }
                },
                {
                    CH: {
                        highRisk: data?.channelHighRisk,
                        medRisk: data?.channelMediumRisk,
                        lowRisk: data?.channelLowRisk,
                        overRideRule: data?.channelOverRideRule
                    }
                }
            ];

            delete data['productServicesHighRisk'];
            delete data['productServicesMediumRisk'];
            delete data['productServicesLowRisk'];
            delete data['productServicesOverRideRule'];
            delete data['channelHighRisk'];
            delete data['channelMediumRisk'];
            delete data['channelLowRisk'];
            delete data['channelOverRideRule'];

            handleStepClick(activeIndex + 1);
            setStepData3(data);

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const setHighValue = (highValue) => {
        setHigh(highValue);
    };

    const setMedValue = (medValue) => {
        setMed(medValue);
    };

    const setLovValues = (lov) => {
        setLov(lov);
    };

    useEffect(() => {
        getProductsLov();
    }, []);

    useEffect(() => {
        const getChannel = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
            setChannelOption(res?.payLoad);
        };
        getChannel();
    }, []);

    useEffect(() => {
        if (riskProfileData) {
            const CTDropdownData = riskProfileData?.tblRpCrpDetails;

            if (CTDropdownData) {
                const CHHighId = [];
                const CHMediumId = [];
                const CHLowId = [];

                const PSHighId = [];
                const PSMediumId = [];
                const PSLowId = [];

                CTDropdownData.forEach((value) => {
                    if (value?.isActive === 'Y') {
                        const referenceIdToString = value?.refId?.toString() || value?.refId;

                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PS') {
                            if (value?.rpRating === 'H') {
                                CHHighId.push(referenceIdToString);

                                formik.setFieldValue('productServicesHighRisk', CHHighId);
                                setSelectedProductsHighRisk(CHHighId);
                                formik.setFieldValue('productServicesOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CHMediumId.push(referenceIdToString);
                                formik.setFieldValue('productServicesMediumRisk', CHMediumId);
                                setSelectedProductsMediumRisk(CHMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CHLowId.push(referenceIdToString);
                                formik.setFieldValue('productServicesLowRisk', CHLowId);
                                // setSelectedProductsLowRisk(CHLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CH') {
                            if (value?.rpRating === 'H') {
                                PSHighId.push(referenceIdToString);
                                formik.setFieldValue('channelHighRisk', PSHighId);
                                setSelectedChannelHighRisk(PSHighId);
                                formik.setFieldValue('channelOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                PSMediumId.push(referenceIdToString);
                                formik.setFieldValue('channelMediumRisk', PSMediumId);
                                setSelectedChannelMediumRisk(PSMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                PSLowId.push(referenceIdToString);
                                formik.setFieldValue('channelLowRisk', PSLowId);
                                // setSelectedChannelLowRisk(PSLowId);
                            }
                        }
                    }
                });
            }
        }
    }, [riskProfileData]);

    useEffect(() => {
        if (lov && (high || med)) {
            // selected values high & medium
            const selectedHighValues = formik?.values?.[high] || [];
            const selectedMedValues = formik?.values?.[med] || [];

            // combine selected high & medium values
            const selectedCtHighMedValues = [...selectedHighValues, ...selectedMedValues];

            // all values from lov
            const allValuesArr = lov.map((values) => values.lovId);

            // remaining values for Low by excluding selected High and Medium values
            const remainingValues = allValuesArr.filter((value) => !selectedCtHighMedValues.includes(value));

            // Set Low dropdown field based on High (replace 'High' with 'Low')
            const formikLowField = high ? high.replace('High', 'Low') : med.replace('Medium', 'Low');
            formik.setFieldValue(formikLowField, remainingValues);
        }
    }, [high, med, formik?.values?.[high], formik?.values?.[med], lov]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* PRODUCTS / SERVICES */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCTS / SERVICES</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesHighRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="productServicesHighRisk"
                                    placeholder="Select High Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    name="productServicesHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedProductsHighRisk(e.value);
                                        formik.setFieldValue('productServicesHighRisk', e.value);
                                        formik.setFieldValue('productServicesOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("productServicesHighRisk");
                                        setLovValues(productLov)
                                    }}
                                    optionDisabled={(option) => combinedProductsMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesMediumRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="productServicesMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    name="productServicesMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedProductsMediumRisk(e.value);
                                        formik.setFieldValue('productServicesMediumRisk', e.value);
                                        setMedValue("productServicesMediumRisk");
                                        setLovValues(productLov)
                                    }}
                                    optionDisabled={(option) => combinedProductsHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesLowRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="productServicesLowRisk"
                                    placeholder="Select Low Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    name="productServicesLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedProductsLowRisk(e.value);
                                        formik.setFieldValue('productServicesLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedProductsHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="productServicesOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="productServicesOverRideRule" disabled name="productServicesOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.productServicesOverRideRule === 'false'} />
                                    <label htmlFor="productServicesOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="productServicesOverRideRule" disabled name="productServicesOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.productServicesOverRideRule === 'true'} />
                                    <label htmlFor="productServicesOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CHANNEL */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CHANNEL</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelHighRisk" className={classNames({ 'p-error': isFormFieldValid('channelHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="channelHighRisk"
                                    placeholder="Select High Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    name="channelHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedChannelHighRisk(e.value);
                                        formik.setFieldValue('channelHighRisk', e.value);
                                        formik.setFieldValue('channelOverRideRule', 'true');
                                        formik.setFieldValue('channelOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("channelHighRisk");
                                        setLovValues(channelOption)
                                    }}
                                    optionDisabled={(option) => combinedChannelMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelMediumRisk" className={classNames({ 'p-error': isFormFieldValid('channelMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="channelMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    name="channelMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedChannelMediumRisk(e.value);
                                        formik.setFieldValue('channelMediumRisk', e.value);
                                        setMedValue("channelMediumRisk");
                                        setLovValues(channelOption)
                                    }}
                                    optionDisabled={(option) => combinedChannelHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelLowRisk" className={classNames({ 'p-error': isFormFieldValid('channelLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="channelLowRisk"
                                    placeholder="Select Low Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    name="channelLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedChannelLowRisk(e.value);
                                        formik.setFieldValue('channelLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedChannelHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="channelOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="channelOverRideRule" disabled name="channelOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.channelOverRideRule === 'false'} />
                                    <label htmlFor="channelOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="channelOverRideRule" disabled name="channelOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.channelOverRideRule === 'true'} />
                                    <label htmlFor="channelOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} icon={loadingIcon || ''} label="Next" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditTransProfProductChannel;
