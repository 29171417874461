import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { getLkpCity } from '../../../../redux/slices/LkpCitySlice';
import { useDispatch, useSelector } from 'react-redux';

function EditGeographicJurisdiction({ activeIndex, handleStepClick, riskProfileData, setStepData2, crpModelIdData }) {

    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [locationLov, setLocationLov] = useState();
    const [districtLov, setDistrictLov] = useState([]);

    // Location of Counter Parties
    const [selectedLocationHighRisk, setSelectedLocationHighRisk] = useState([]);
    const [selectedLocationMediumRisk, setSelectedLocationMediumRisk] = useState([]);
    const [selectedLocationLowRisk, setSelectedLocationLowRisk] = useState([]);

    const combinedLocationMediumLowRisk = [...selectedLocationMediumRisk, ...selectedLocationLowRisk];
    const combinedLocationHighLowRisk = [...selectedLocationHighRisk, ...selectedLocationLowRisk];
    const combinedLocationHighMediumRisk = [...selectedLocationHighRisk, ...selectedLocationMediumRisk];

    // Permanent Address Location (City)
    const [selectedCityHighRisk, setSelectedCityHighRisk] = useState([]);
    const [selectedCityMediumRisk, setSelectedCityMediumRisk] = useState([]);
    const [selectedCityLowRisk, setSelectedCityLowRisk] = useState([]);

    const combinedCityMediumLowRisk = [...selectedCityMediumRisk, ...selectedCityLowRisk];
    const combinedCityHighLowRisk = [...selectedCityHighRisk, ...selectedCityLowRisk];
    const combinedCityHighMediumRisk = [...selectedCityHighRisk, ...selectedCityMediumRisk];

    // MAILING ADDRESS/GEO-LOCATION COORDINATES OF DIGITAL GADGETS THROUGH WHICH, CUSTOMER HAS REQUESTED FOR DIGITAL ONBOARDING (District)
    const [selectedDistrictHighRisk, setSelectedDistrictHighRisk] = useState([]);
    const [selectedDistrictMediumRisk, setSelectedDistrictMediumRisk] = useState([]);
    const [selectedDistrictLowRisk, setSelectedDistrictLowRisk] = useState([]);

    const combinedDistrictMediumLowRisk = [...selectedDistrictMediumRisk, ...selectedDistrictLowRisk];
    const combinedDistrictHighLowRisk = [...selectedDistrictHighRisk, ...selectedDistrictLowRisk];
    const combinedDistrictHighMediumRisk = [...selectedDistrictHighRisk, ...selectedDistrictMediumRisk];

    const [high, setHigh] = useState(null);
    const [med, setMed] = useState(null);
    const [lov, setLov] = useState([]);

    const dispatch = useDispatch();

    const lkpCityLov = useSelector((state) => state?.LkpCitySlice?.data);

    const getHraLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gethraaccountdata');
        setLocationLov(resp?.payLoad?.lkpCountry);
    };

    const formik = useFormik({
        initialValues: {
            stepNo: 2,
            counterPartiesHighRisk: [],
            counterPartiesMediumRisk: [],
            counterPartiesLowRisk: [],
            counterPartiesOverRideRule: 'false',
            permanentAddressHighRisk: [],
            permanentAddressMediumRisk: [],
            permanentAddressLowRisk: [],
            permanentAddressOverRideRule: 'false',
            mailingAddressHighRisk: [],
            mailingAddressMediumRisk: [],
            mailingAddressLowRisk: [],
            mailingAddressOverRideRule: 'false',
            reqMap: []
        },
        //     let errors = {};
        //     if (data?.counterPartiesHighRisk?.length === 0) {
        //         errors.counterPartiesHighRisk = 'This field is required';
        //     }
        //     if (data?.counterPartiesMediumRisk?.length === 0) {
        //         errors.counterPartiesMediumRisk = 'This field is required';
        //     }
        //     if (data?.counterPartiesLowRisk?.length === 0) {
        //         errors.counterPartiesLowRisk = 'This field is required';
        //     }
        //     if (data?.permanentAddressHighRisk?.length === 0) {
        //         errors.permanentAddressHighRisk = 'This field is required';
        //     }
        //     if (data?.permanentAddressMediumRisk?.length === 0) {
        //         errors.permanentAddressMediumRisk = 'This field is required';
        //     }
        //     if (data?.permanentAddressLowRisk?.length === 0) {
        //         errors.permanentAddressLowRisk = 'This field is required';
        //     }
        //     if (data?.mailingAddressHighRisk?.length === 0) {
        //         errors.mailingAddressHighRisk = 'This field is required';
        //     }
        //     if (data?.mailingAddressMediumRisk?.length === 0) {
        //         errors.mailingAddressMediumRisk = 'This field is required';
        //     }
        //     if (data?.mailingAddressLowRisk?.length === 0) {
        //         errors.mailingAddressLowRisk = 'This field is required';
        //     }
        //     return errors;
        // },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['crpId'] = riskProfileData?.rpCrpId;
            data['rpCrpModelId'] = crpModelIdData;

            data['reqMap'] = [
                {
                    LC: {
                        highRisk: data?.counterPartiesHighRisk,
                        medRisk: data?.counterPartiesMediumRisk,
                        lowRisk: data?.counterPartiesLowRisk,
                        overRideRule: data?.counterPartiesOverRideRule
                    }
                },
                {
                    PTA: {
                        highRisk: data?.permanentAddressHighRisk,
                        medRisk: data?.permanentAddressMediumRisk,
                        lowRisk: data?.permanentAddressLowRisk,
                        overRideRule: data?.permanentAddressOverRideRule
                    }
                },
                {
                    MA: {
                        highRisk: data?.mailingAddressHighRisk,
                        medRisk: data?.mailingAddressMediumRisk,
                        lowRisk: data?.mailingAddressLowRisk,
                        overRideRule: data?.mailingAddressOverRideRule
                    }
                }
            ];
            delete data['counterPartiesHighRisk'];
            delete data['counterPartiesMediumRisk'];
            delete data['counterPartiesLowRisk'];
            delete data['counterPartiesOverRideRule'];
            delete data['permanentAddressHighRisk'];
            delete data['permanentAddressMediumRisk'];
            delete data['permanentAddressLowRisk'];
            delete data['permanentAddressOverRideRule'];
            delete data['mailingAddressHighRisk'];
            delete data['mailingAddressMediumRisk'];
            delete data['mailingAddressLowRisk'];
            delete data['mailingAddressOverRideRule'];

            handleStepClick(activeIndex + 1);
            setStepData2(data);

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const setHighValue = (highValue) => {
        setHigh(highValue);
    };

    const setMedValue = (medValue) => {
        setMed(medValue);
    };

    const setLovValues = (lov) => {
        setLov(lov);
    };

    useEffect(() => {
        getHraLov();
    }, []);

    useEffect(() => {
        if (lkpCityLov === undefined || lkpCityLov.length === 0) dispatch(getLkpCity());
        const getDistrictData = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/getAllDistrict/LKP_DISTRICT:*');
            setDistrictLov(res?.payLoad);
        };
        getDistrictData();
    }, []);

    useEffect(() => {
        if (riskProfileData) {
            const CTDropdownData = riskProfileData?.tblRpCrpDetails;

            if (CTDropdownData) {
                const LCHighId = [];
                const LCMediumId = [];
                const LCLowId = [];

                const PTAHighId = [];
                const PTAMediumId = [];
                const PTALowId = [];

                const MAHighId = [];
                const MAMediumId = [];
                const MALowId = [];

                CTDropdownData.forEach((value) => {
                    if (value?.isActive === 'Y') {
                        const referenceId = value?.refId || value?.refId?.toString();

                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'LC') {
                            if (value?.rpRating === 'H') {
                                LCHighId.push(referenceId);

                                formik.setFieldValue('counterPartiesHighRisk', LCHighId);
                                setSelectedLocationHighRisk(LCHighId);
                                formik.setFieldValue('counterPartiesOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                LCMediumId.push(referenceId);
                                formik.setFieldValue('counterPartiesMediumRisk', LCMediumId);
                                setSelectedLocationMediumRisk(LCMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                LCLowId.push(referenceId);
                                formik.setFieldValue('counterPartiesLowRisk', LCLowId);
                                // setSelectedLocationLowRisk(LCLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PTA') {
                            if (value?.rpRating === 'H') {
                                PTAHighId.push(referenceId);

                                const convertedPTAHighId = PTAHighId.map(String);
                                formik.setFieldValue('permanentAddressHighRisk', convertedPTAHighId);
                                setSelectedCityHighRisk(PTAHighId);
                                formik.setFieldValue('permanentAddressOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                PTAMediumId.push(referenceId);
                                const convertedPTAMediumId = PTAMediumId.map(String);
                                formik.setFieldValue('permanentAddressMediumRisk', convertedPTAMediumId);
                                setSelectedCityMediumRisk(PTAMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                PTALowId.push(referenceId);
                                const convertedPTALowId = PTALowId.map(String);
                                formik.setFieldValue('permanentAddressLowRisk', convertedPTALowId);
                                // setSelectedCityLowRisk(PTALowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'MA') {
                            if (value?.rpRating === 'H') {
                                MAHighId.push(referenceId);
                                const convertedMAHighId = MAHighId.map(String);
                                formik.setFieldValue('mailingAddressHighRisk', convertedMAHighId);
                                setSelectedDistrictHighRisk(MAHighId);
                                formik.setFieldValue('mailingAddressOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                MAMediumId.push(referenceId);
                                const convertedMAMediumId = MAMediumId.map(String);
                                formik.setFieldValue('mailingAddressMediumRisk', convertedMAMediumId);
                                setSelectedDistrictMediumRisk(MAMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                MALowId.push(referenceId);
                                const convertedMALowId = MALowId.map(String);
                                formik.setFieldValue('mailingAddressLowRisk', convertedMALowId);
                                // setSelectedDistrictLowRisk(MALowId);
                            }
                        }
                    }
                });
            }
        }
    }, [riskProfileData]);

    useEffect(() => {

        if (lov && (high || med)) {
            // selected values high & medium
            const selectedHighValues = formik?.values?.[high] || [];
            const selectedMedValues = formik?.values?.[med] || [];

            // combine selected high & medium values
            const selectedCtHighMedValues = [...selectedHighValues, ...selectedMedValues];

            // all values from lov
            const allValuesArr = lov.map((values) =>
                values.lovId || values.countryId
            );

            // remaining values for Low by excluding selected High and Medium values
            const remainingValues = allValuesArr.filter((value) => !selectedCtHighMedValues.includes(value));

            // Set Low dropdown field based on High (replace 'High' with 'Low')
            const formikLowField = high ? high.replace('High', 'Low') : med.replace('Medium', 'Low');
            formik.setFieldValue(formikLowField, remainingValues);
        }
    }, [high, med, formik?.values?.[high], formik?.values?.[med], lov]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* LOCATION OF COUNTER PARTIES */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>LOCATION OF COUNTER PARTIES</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="counterPartiesHighRisk" className={classNames({ 'p-error': isFormFieldValid('counterPartiesHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="counterPartiesHighRisk"
                                    placeholder="Select High Risk"
                                    options={locationLov}
                                    optionLabel="countryName"
                                    name="counterPartiesHighRisk"
                                    optionValue="countryId"
                                    value={formik?.values?.counterPartiesHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedLocationHighRisk(e.value);
                                        formik.setFieldValue('counterPartiesHighRisk', e.value);
                                        formik.setFieldValue('counterPartiesOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("counterPartiesHighRisk");
                                        setLovValues(locationLov)
                                    }}
                                    optionDisabled={(option) => combinedLocationMediumLowRisk.includes(option.countryId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('counterPartiesHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="counterPartiesMediumRisk" className={classNames({ 'p-error': isFormFieldValid('counterPartiesMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="counterPartiesMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={locationLov}
                                    optionLabel="countryName"
                                    name="counterPartiesMediumRisk"
                                    optionValue="countryId"
                                    value={formik?.values?.counterPartiesMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedLocationMediumRisk(e.value);
                                        formik.setFieldValue('counterPartiesMediumRisk', e.value);
                                        setMedValue("counterPartiesMediumRisk");
                                        setLovValues(locationLov)
                                    }}
                                    optionDisabled={(option) => combinedLocationHighLowRisk.includes(option.countryId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('counterPartiesMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="counterPartiesLowRisk" className={classNames({ 'p-error': isFormFieldValid('counterPartiesLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="counterPartiesLowRisk"
                                    placeholder="Select Low Risk"
                                    options={locationLov}
                                    optionLabel="countryName"
                                    name="counterPartiesLowRisk"
                                    optionValue="countryId"
                                    value={formik?.values?.counterPartiesLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedLocationLowRisk(e.value);
                                        formik.setFieldValue('counterPartiesLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedLocationHighMediumRisk.includes(option.countryId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('counterPartiesLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="counterPartiesOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="counterPartiesOverRideRule" disabled name="counterPartiesOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.counterPartiesOverRideRule === 'false'} />
                                    <label htmlFor="counterPartiesOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="counterPartiesOverRideRule" disabled name="counterPartiesOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.counterPartiesOverRideRule === 'true'} />
                                    <label htmlFor="counterPartiesOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PERMANENT ADDRESS LOCATION */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PERMANENT ADDRESS LOCATION</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="permanentAddressHighRisk" className={classNames({ 'p-error': isFormFieldValid('permanentAddressHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="permanentAddressHighRisk"
                                    placeholder="Select High Risk"
                                    options={lkpCityLov}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    name="permanentAddressHighRisk"
                                    value={formik?.values?.permanentAddressHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCityHighRisk(e.value);
                                        formik.setFieldValue('permanentAddressHighRisk', e.value);
                                        formik.setFieldValue('permanentAddressOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("permanentAddressHighRisk");
                                        setLovValues(lkpCityLov)
                                    }}
                                    optionDisabled={(option) => combinedCityMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="permanentAddressMediumRisk" className={classNames({ 'p-error': isFormFieldValid('permanentAddressMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="permanentAddressMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={lkpCityLov}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    name="permanentAddressMediumRisk"
                                    value={formik?.values?.permanentAddressMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCityMediumRisk(e.value);
                                        formik.setFieldValue('permanentAddressMediumRisk', e.value);
                                        setMedValue("permanentAddressMediumRisk");
                                        setLovValues(lkpCityLov)
                                    }}
                                    optionDisabled={(option) => combinedCityHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="permanentAddressLowRisk" className={classNames({ 'p-error': isFormFieldValid('permanentAddressLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="permanentAddressLowRisk"
                                    placeholder="Select Low Risk"
                                    options={lkpCityLov}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    name="permanentAddressLowRisk"
                                    value={formik?.values?.permanentAddressLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCityLowRisk(e.value);
                                        formik.setFieldValue('permanentAddressLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedCityHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="permanentAddressOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="permanentAddressOverRideRule" disabled name="permanentAddressOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.permanentAddressOverRideRule === 'false'} />
                                    <label htmlFor="permanentAddressOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="permanentAddressOverRideRule" disabled name="permanentAddressOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.permanentAddressOverRideRule === 'true'} />
                                    <label htmlFor="permanentAddressOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* MAILING ADDRESS/GEO-LOCATION COORDINATES OF DIGITAL GADGETS THROUGH WHICH, CUSTOMER HAS REQUESTED FOR DIGITAL ONBOARDING */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>MAILING ADDRESS/GEO-LOCATION COORDINATES OF DIGITAL GADGETS THROUGH WHICH, CUSTOMER HAS REQUESTED FOR DIGITAL ONBOARDING</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mailingAddressHighRisk" className={classNames({ 'p-error': isFormFieldValid('mailingAddressHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="mailingAddressHighRisk"
                                    placeholder="Select High Risk"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="mailingAddressHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.mailingAddressHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedDistrictHighRisk(e.value);
                                        formik.setFieldValue('mailingAddressHighRisk', e.value);
                                        formik.setFieldValue('mailingAddressOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("mailingAddressHighRisk");
                                        setLovValues(districtLov)
                                    }}
                                    optionDisabled={(option) => combinedDistrictMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mailingAddressMediumRisk" className={classNames({ 'p-error': isFormFieldValid('mailingAddressMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="mailingAddressMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="mailingAddressMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.mailingAddressMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedDistrictMediumRisk(e.value);
                                        formik.setFieldValue('mailingAddressMediumRisk', e.value);
                                        setMedValue("mailingAddressMediumRisk");
                                        setLovValues(districtLov)
                                    }}
                                    optionDisabled={(option) => combinedDistrictHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mailingAddressLowRisk" className={classNames({ 'p-error': isFormFieldValid('mailingAddressLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="mailingAddressLowRisk"
                                    placeholder="Select Low Risk"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="mailingAddressLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.mailingAddressLowRisk || ''}
                                    onChange={(e) => {
                                        setSelectedDistrictLowRisk(e.value);
                                        formik.setFieldValue('mailingAddressLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedDistrictHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="mailingAddressOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="mailingAddressOverRideRule" disabled name="mailingAddressOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.mailingAddressOverRideRule === 'false'} />
                                    <label htmlFor="mailingAddressOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="mailingAddressOverRideRule" disabled name="mailingAddressOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.mailingAddressOverRideRule === 'true'} />
                                    <label htmlFor="mailingAddressOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} icon={loadingIcon || ''} label="Next" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditGeographicJurisdiction;
