import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { handleGetRequest } from '../../services/GetTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import ViewCustomerBusinessRisk from './Components/CustomerBusinessRisk/ViewCustomerBusinessRisk';
import ViewGeographicJurisdiction from './Components/GeographicJurisdiction/ViewGeographicJurisdiction';
import ViewTransProfProductChannel from './Components/TransProfProductChannel/ViewTransProfProductChannel';
import ViewTransactionProfiling from './Components/TransactionProfiling/ViewTransactionProfiling';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCustomerRiskProfile() {
    const [loading, setloading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [riskProfileData, setRiskProfileData] = useState();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const riskProfileId = query.get('riskProfileId');
    const tableName = 'TBL_RP_CRP';

    const navigate = useNavigate();

    const getRiskProfileDataById = async () => {
        const response = await handleGetRequest(`/riskprofile/v1/crp/getcrp/${riskProfileId}`);
        setRiskProfileData(response?.payLoad);
    };

    useEffect(() => {
        getRiskProfileDataById();
    }, [riskProfileId]); //eslint-disable-line

    const formik = useFormik({
        initialValues: {},

        onSubmit: async () => {
            setloading(true);

            setloading(false);
        }
    });

    const items = [
        {
            label: 'Customer / Business Risk',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 0 ? false : true,
        },
        {
            label: 'Geographic / Jurisdiction',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 1 ? false : true
        },
        {
            label: 'Transaction Profiling - Products / Channels',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 2 ? false : true
        },
        {
            label: 'Transaction Profiling',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(riskProfileData?.currentStep) || activeIndex >= 3 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />

                {activeIndex === 0 && <ViewCustomerBusinessRisk activeIndex={activeIndex} handleStepClick={handleStepClick} riskProfileData={riskProfileData} />}
                {activeIndex === 1 && <ViewGeographicJurisdiction activeIndex={activeIndex} handleStepClick={handleStepClick} riskProfileData={riskProfileData} />}
                {activeIndex === 2 && <ViewTransProfProductChannel activeIndex={activeIndex} handleStepClick={handleStepClick} riskProfileData={riskProfileData} />}
                {activeIndex === 3 && <ViewTransactionProfiling activeIndex={activeIndex} handleStepClick={handleStepClick} riskProfileData={riskProfileData} />}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex === 3 && (
                        <div className="Down__Btn">
                            <Button disabled={loading} onClick={() => navigate('/customerriskprofile')} label="Okay" className="Btn__Dark" />
                        </div>
                    )}
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={riskProfileId} tableName={tableName} />
        </>
    );
}
export default ViewCustomerRiskProfile;
